import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as UserCreators } from 'store/userStore';

import isEmpty from 'helpers/isEmpty';
import DealerService from 'services/DealerService';
import DealersModalComponent from './DealersModalComponent';

const DealersModalContainer = ({ selectModalActions }) => {
  const dispatch = useDispatch();

  const dealers = useSelector((state: any) => state?.userStore?.dealers);

  const [page, setPage] = React.useState(1);
  const [searchValue, setSearchValue] = React.useState('');
  const [isLoading, setLoading] = React.useState(true);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const getDealer = React.useCallback(
    async (search, page) => {
      try {
        setLoading(true);
        const response: any = await DealerService.GetDealers(search, page - 1);

        if (response.ok) {
          dispatch(UserCreators.setDealers(response.data));
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  const handleKeyPress = async (event, search) => {
    if (!isEmpty(search)) {
      if (event.key === 'Enter') {
        await getDealer(search, 1);
      }
    }
  };

  const handleDealerOnClick = async (search) => {
    await getDealer(search, 1);
  };

  function handlePageChange(e: any, value: number) {
    setPage(value);
  }

  React.useEffect(() => {
    getDealer('', page);
  }, [getDealer, page]);

  return (
    <DealersModalComponent
      dealers={dealers?.content}
      selectModalActions={selectModalActions}
      searchValue={searchValue}
      handleKeyPress={handleKeyPress}
      handleInputChange={handleInputChange}
      handleDealerOnClick={handleDealerOnClick}
      page={page}
      totalPages={dealers?.totalPages}
      handlePageChange={handlePageChange}
      isLoading={isLoading}
    />
  );
};

export default DealersModalContainer;
