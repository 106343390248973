import React from 'react';
import { Box } from '@mui/material';

import Facebook from 'assets/icons/facebook';
import Youtube from 'assets/icons/youtube';
import Instagram from 'assets/icons/instagram';
import TikTokIcon from 'assets/icons/tiktok';
import ThreadsIcon from 'assets/icons/threads';

const SocialIcons = ({ color = '#424242', ...rest }) => {
  return (
    <Box
      component="div"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.facebook.com/passecarrosmobiauto"
      >
        <Facebook color={color} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.youtube.com/passecarros"
      >
        <Youtube color={color} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.instagram.com/mobiauto/ "
      >
        <Instagram color={color} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.threads.net/@mobiauto"
      >
        <ThreadsIcon color={color} />
      </a>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://www.tiktok.com/@mobiauto?lang=pt-BR"
      >
        <TikTokIcon color={color} />
      </a>
    </Box>
  );
};

export default SocialIcons;
