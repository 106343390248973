import React from 'react';

function YoutubeIcon({ color = '#424242' }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 7.72516C1 5.38699 2.70926 3.28516 5.05778 3.28516H18.9422C21.2907 3.28516 23 5.38699 23 7.72516V16.8452C23 19.1833 21.2907 21.2852 18.9422 21.2852H5.05778C3.94103 21.2852 2.8976 20.7851 2.14819 19.942C1.40219 19.1027 1 17.9872 1 16.8452V7.72516ZM5.05778 5.28516C4.02877 5.28516 3 6.2636 3 7.72516V16.8452C3 17.5278 3.24212 18.1622 3.64301 18.6132C4.04049 19.0604 4.55258 19.2852 5.05778 19.2852H18.9422C19.9712 19.2852 21 18.3067 21 16.8452V7.72516C21 6.2636 19.9712 5.28516 18.9422 5.28516H5.05778Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10.9032V13.6671L13.7639 12.2852L11 10.9032ZM9 10.0942C9 8.9791 10.1735 8.25386 11.1708 8.75253L15.5528 10.9435C16.6584 11.4963 16.6584 13.074 15.5528 13.6268L11.1708 15.8178C10.1735 16.3165 9 15.5912 9 14.4761V10.0942Z"
        fill={color}
      />
    </svg>
  );
}

export default YoutubeIcon;
