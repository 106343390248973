const getImageTransformInfos = (
  quality,
  width,
  height,
  crop,
  gravity,
  radius,
  backgroundColor,
  png,
) => {
  let transformationInfo = '';
  if (quality) {
    transformationInfo += `q_${quality}`;
  }
  if (width) {
    transformationInfo += `${transformationInfo ? ',' : ''}w_${width}`;
  }
  if (height) {
    transformationInfo += `${transformationInfo ? ',' : ''}h_${height}`;
  }
  if (crop) {
    transformationInfo += `${transformationInfo ? ',' : ''}c_${crop}`;
  }
  if (gravity) {
    transformationInfo += `${transformationInfo ? ',' : ''}g_${gravity}`;
  }
  if (radius) {
    transformationInfo += `${transformationInfo ? ',' : ''}r_${radius}`;
  }
  if (backgroundColor) {
    transformationInfo += `${
      transformationInfo ? ',' : ''
    }b_${backgroundColor}`;
  }
  if (png) {
    transformationInfo += `${
      transformationInfo ? ',' : ''
    }fl_progressive,f_png`;
  }
  return transformationInfo;
};

export default getImageTransformInfos;
