import React from 'react';
import {
  NavLink as NavRouterLink,
  Link as RouterLink,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import ButtonBase from '@mui/material/ButtonBase';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, ListItem, useTheme } from '@mui/material';

import Close from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import useModal from 'hooks/useModal';
import UserInfo from 'components/atoms/UserInfo';
import { ReactComponent as Logo } from 'assets/passe_carros_logo.svg';
import DealersModal from 'components/atoms/Modal/DealersModal';
import { useSelector } from 'react-redux';
import MobicoinBalance from 'components/atoms/MobicoinBalance';
import GiroPanButton from 'components/atoms/Button/GiroPanButton';
import Skeleton from '@mui/material/Skeleton';
import useGiroPan from 'hooks/useGiroPan';
import { DefaultTheme } from 'styles/theme/defaultTheme';
import styled from '@emotion/styled';
import { logout } from 'auth/AuthService';
import { Logout } from '@mui/icons-material';
import Button from 'components/atoms/Button/Button';

const navLinks = [
  {
    path: '/passe-carros',
    label: 'Ofertas',
    end: false,
  },
  {
    path: '/passe-carros/favoritos',
    label: 'Favoritos',
    end: true,
  },
];

const ButtonWrapper = styled('div')(({ theme: { palette, borderRadius } }) => ({
  '& button': {
    background: palette.secondary.main,
    color: palette.other.contrastText,
    height: '40px',
    padding: '0 16px',
    fontWeight: 500,
    borderRadius: borderRadius.small,
    fontSize: '14px',
  },
}));

const Header: React.FC = () => {
  const theme = useTheme<DefaultTheme>();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const currentDealer = useSelector(
    (state: any) => state.userStore?.currentDealer,
  );

  const selectModalActions = useModal();

  const [open, setOpen] = React.useState(false);

  const { loading, status, IsElegibleComponent, redirectByUrl } = useGiroPan();

  const NavLink = React.forwardRef((props: any, ref: any) => (
    <div ref={ref}>
      <NavRouterLink
        {...props}
        className={({ isActive }) =>
          `${props.className} ${isActive ? props.activeClassName : undefined}`
        }
        style={{ color: 'inherit' }}
      />
    </div>
  ));

  const handleClickOnGoToMyProposals = () => {
    navigate('/passe-carros/minhas-propostas');
    setOpen(false);
  };

  return (
    <>
      <Box component="header" bgcolor="#FFF">
        <Container maxWidth="lg">
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={60}
            sx={{
              '& > a': {
                lineHeight: '0',
              },
            }}
          >
            <Link component={RouterLink} to="/passe-carros">
              <Logo />
            </Link>
            {desktop ? (
              <>
                <Box component="ul" display="flex" alignItems="center">
                  {navLinks.map(({ label, path, end }) => (
                    <List aria-label="Main List" key={label}>
                      <ListItem component={NavLink} to={path} end>
                        <Box
                          component="span"
                          fontWeight={700}
                          fontSize="14px"
                          color={
                            path === location?.pathname
                              ? 'primary.main'
                              : 'inherit'
                          }
                        >
                          {label}
                        </Box>
                      </ListItem>
                    </List>
                  ))}
                </Box>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    width={300}
                    height={36}
                    style={{ borderRadius: '8px' }}
                  />
                ) : (
                  <IsElegibleComponent>
                    <Box component="div">
                      <GiroPanButton variant={status} onClick={redirectByUrl}>
                        {status === 'PRE_APROVE'
                          ? 'PRÉ-APROVADO'
                          : 'CRÉDITO DISPONÍVEL'}
                      </GiroPanButton>
                    </Box>
                  </IsElegibleComponent>
                )}
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  style={{ gap: 16 }}
                >
                  <MobicoinBalance />
                  <Box
                    component="div"
                    height="32px"
                    width="1px"
                    bgcolor="#E0E0E0"
                  />
                  <ButtonWrapper>
                    <ButtonBase
                      onClick={() => navigate('/passe-carros/minhas-propostas')}
                    >
                      Minhas propostas
                    </ButtonBase>
                  </ButtonWrapper>
                  <UserInfo
                    open={open}
                    selectModalActions={selectModalActions}
                  />
                </Box>
              </>
            ) : (
              <Box component="div" display="flex" alignItems="center" gap="8px">
                <ButtonBase
                  onClick={() => selectModalActions.handleOpenModal()}
                >
                  <Box
                    component="p"
                    fontSize="14px"
                    display="flex"
                    alignItems="center"
                  >
                    {currentDealer?.name} <ExpandMoreIcon fontSize="small" />
                  </Box>
                </ButtonBase>
                <MenuIcon
                  onClick={() => {
                    setOpen(!open);
                  }}
                />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      {!desktop && (
        <Box
          component="div"
          width="100%"
          display="flex"
          justifyContent="center"
          py={2}
          bgcolor="#f0f0f0"
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={300}
              height={36}
              style={{ borderRadius: '8px' }}
            />
          ) : (
            <IsElegibleComponent>
              <Box component="div" display="flex" justifyContent="center">
                <GiroPanButton variant={status}>
                  {status === 'PRE_APROVE'
                    ? 'PRÉ-APROVADO'
                    : 'CRÉDITO DISPONÍVEL'}
                </GiroPanButton>
              </Box>
            </IsElegibleComponent>
          )}
        </Box>
      )}
      {!desktop && open && (
        <Box
          component="nav"
          position="fixed"
          height="100vh"
          width="100%"
          top={0}
          bgcolor="#fff"
          zIndex={99999999}
        >
          <Container maxWidth="lg">
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="60px"
            >
              <Logo />
              <Close onClick={() => setOpen(false)} />
            </Box>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="32px"
              mt={2}
            >
              {navLinks.map(({ label, path, end }) => (
                <Box
                  color="#424242"
                  component="span"
                  fontSize="14px"
                  fontWeight={700}
                  key={label}
                  style={{ listStyle: 'none' }}
                  onClick={() => setOpen(false)}
                >
                  <Link component={RouterLink} to={path} color="textSecondary">
                    {label}
                  </Link>
                </Box>
              ))}
            </Box>
            <Box component="div" style={{ padding: '24px 0px' }}>
              <ButtonWrapper>
                <ButtonBase onClick={handleClickOnGoToMyProposals}>
                  Minhas propostas
                </ButtonBase>
              </ButtonWrapper>
              <Button
                type="text"
                color="inherit"
                sx={{
                  padding: 0,
                  fontSize: '16px',
                }}
                onClick={() => logout()}
                startIcon={<Logout fontSize="medium" />}
              >
                Sair
              </Button>
            </Box>
          </Container>
        </Box>
      )}
      {selectModalActions.isOpen ? (
        <DealersModal selectModalActions={selectModalActions} />
      ) : null}
    </>
  );
};

export default Header;
