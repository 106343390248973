import { useSelector } from 'react-redux';

const useDealerId = () => {
  const dealerId = useSelector(
    ({ userStore }: any) => userStore?.currentDealer?.id,
  );
  return dealerId;
};

export default useDealerId;
