import React from 'react';

function FacebookIcon({ color = '#424242' }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33104 4.09594C10.0905 3.01424 11.3652 2.28516 13.2561 2.28516H16.7879C17.3402 2.28516 17.7879 2.73287 17.7879 3.28516V6.48373C17.7879 7.03602 17.3402 7.48373 16.7879 7.48373H14.5253V7.89067H17C17.2833 7.89067 17.5533 8.01084 17.743 8.22133C17.9326 8.43182 18.024 8.71287 17.9946 8.99465L17.6602 12.1932C17.6069 12.7025 17.1776 13.0892 16.6656 13.0892H14.5253V21.2852C14.5253 21.8374 14.0776 22.2852 13.5253 22.2852H9.44698C8.8947 22.2852 8.44698 21.8374 8.44698 21.2852V13.0892H7C6.44772 13.0892 6 12.6415 6 12.0892V8.89067C6 8.33838 6.44772 7.89067 7 7.89067H8.44698V7.17143C8.44698 6.32241 8.61441 5.11665 9.33104 4.09594ZM10.9679 5.24515C10.5813 5.79573 10.447 6.5331 10.447 7.17143V8.89067C10.447 9.44295 9.99927 9.89067 9.44698 9.89067H8V11.0892H9.44698C9.99927 11.0892 10.447 11.537 10.447 12.0892V20.2852H12.5253V12.0892C12.5253 11.537 12.973 11.0892 13.5253 11.0892H15.7647L15.89 9.89067H13.5253C12.973 9.89067 12.5253 9.44295 12.5253 8.89067V7.40414C12.4918 6.91059 12.6648 6.42707 13.0001 6.06552C13.3477 5.69067 13.8362 5.48206 14.3429 5.48373L14.3459 5.48375L14.3409 5.48373H14.3429H15.7879V4.28516H13.2561C11.9415 4.28516 11.3116 4.75554 10.9679 5.24515Z"
        fill={color}
      />
    </svg>
  );
}

export default FacebookIcon;
