import React from 'react';

function ThreadsIcon({ color = '#424242' }) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1822 0.409912C7.09016 0.431912 4.70016 1.47291 3.09416 3.51591C1.66616 5.33091 0.934156 7.86391 0.910156 11.0059V11.0079C0.935156 14.1509 1.66616 16.6699 3.09416 18.4849C4.70016 20.5269 7.10316 21.5689 10.1942 21.5899H10.1962C12.9442 21.5709 14.8932 20.8499 16.4992 19.2459C18.6032 17.1429 18.5412 14.5049 17.8462 12.8829C17.3162 11.6489 16.2712 10.6619 14.8702 10.0479C14.6902 7.06291 13.0102 5.32191 10.2502 5.30391C8.62016 5.29391 7.14816 6.02391 6.24716 7.39091L7.90216 8.52691C8.43516 7.71791 9.27916 7.32791 10.2372 7.33691C11.6242 7.34591 12.5372 8.11091 12.7922 9.45391C11.9702 9.33108 11.1375 9.29588 10.3082 9.34891C7.66816 9.50091 5.94016 11.0609 6.05516 13.2239C6.17516 15.4859 8.36716 16.7189 10.4482 16.6049C12.9402 16.4679 14.4212 14.6289 14.7722 12.2839C15.3492 12.6569 15.7752 13.1339 16.0162 13.6969C16.4562 14.7219 16.4842 16.4129 15.1012 17.7949C13.8842 19.0109 12.4212 19.5409 10.1892 19.5569C7.71416 19.5389 5.85716 18.7459 4.65216 17.2139C3.52016 15.7739 2.92816 13.6879 2.90616 10.9999C2.92816 8.31191 3.52016 6.22491 4.65216 4.78691C5.85716 3.25391 7.71416 2.46191 10.1892 2.44291C12.6822 2.46191 14.5732 3.25791 15.8252 4.79891C16.5162 5.64891 16.9492 6.66491 17.2382 7.71391L19.1782 7.19691C18.8152 5.85891 18.2412 4.58391 17.3632 3.50291C15.7102 1.46891 13.2812 0.431912 10.1822 0.409912ZM10.4202 11.3779C11.2342 11.3238 12.0517 11.3763 12.8522 11.5339C12.7122 13.1119 12.0592 14.4809 10.3402 14.5749C9.22816 14.6379 8.10316 14.1409 8.04816 13.1139C8.00816 12.3499 8.57316 11.4839 10.4202 11.3779Z"
        fill={color}
      />
    </svg>
  );
}

export default ThreadsIcon;
