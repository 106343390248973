import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Divider,
} from '@mui/material';
import moment from 'moment';

import RuleTerms from '../RuleTerms';
import SocialIcons from '../SocialIcons';

import { ReactComponent as Logo } from 'assets/passe_carros_logo.svg';
import { DefaultTheme } from 'styles/theme/defaultTheme';

const Footer = () => {
  const theme = useTheme<DefaultTheme>();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const currentYear = moment().year();

  return (
    <>
      <Box component="footer" bgcolor="#06B4A80A">
        <Container maxWidth="lg">
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            margin="0 auto"
            textAlign="center"
            gap="16px"
            py={{ xs: 2, md: 5 }}
          >
            <Box width="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="296px"
                margin={{ xs: '0 auto', md: '0px' }}
                sx={{
                  '& > a': {
                    lineHeight: '0',
                  },
                }}
              >
                <Link to="/">
                  <Logo width={96} height={60} />
                </Link>
              </Box>
              <Box
                component="p"
                fontSize="12px"
                lineHeight="18px"
                fontWeight={400}
                width={{ xs: '100%', md: '296px' }}
                mt={2}
                textAlign="center"
              >
                Um jeito inteligente de
                <br /> comprar e vender carros e motos
              </Box>
            </Box>
            <SocialIcons gap="16px" />
          </Box>
        </Container>
      </Box>
      <Box
        maxWidth="1280px"
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        p={2}
        m="0 auto"
        justifyContent="space-between"
      >
        <Box
          component="div"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pb={{ xs: 2, md: 0 }}
        >
          <RuleTerms
            gap={{ xs: '16px', md: '40px' }}
            flexDirection={{ xs: 'column', md: 'row' }}
          />
        </Box>
        {!desktop && <Divider variant="fullWidth" />}
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          pt={{ xs: 2, md: 0 }}
        >
          <Box mr={{ xs: '0', md: '16px' }} fontSize="14px">
            {`© ${currentYear} Todos os direitos reservados.`}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
