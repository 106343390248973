import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles({
  root: {
    overflow: 'hidden',
    maxWidth: '300px',
  },
  rootActions: {
    overflow: 'hidden',
    justifyContent: 'center',
    margin: 16,
    padding: 0,
  },
});

const ModalBaseComponent = ({
  modalActions,
  headerTitle = 'Header Title',
  headerTextColor = 'other.primary',
  children,
  bgColor = '#fff',
  disableHeader = false,
  ...rest
}) => {
  const classes = styles();

  return (
    <Dialog
      open={modalActions?.isOpen}
      onClose={modalActions?.handleCloseModal}
      maxWidth="lg"
      {...rest}
    >
      {!disableHeader && (
        <>
          <DialogTitle>
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              bgcolor={bgColor}
              alignItems="center"
              px={{ xs: 2, md: 3 }}
              py={{ xs: 2 }}
            >
              <Box
                component="strong"
                fontSize={{ xs: '16px', md: '24px' }}
                color={headerTextColor}
                fontWeight={700}
              >
                {headerTitle}
              </Box>
              <IconButton onClick={modalActions?.handleCloseModal} size="large">
                <CloseIcon fontSize="small" htmlColor={headerTextColor} />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
        </>
      )}
      <DialogContent>{children}</DialogContent>
      {modalActions.DialogActions && (
        <DialogActions className={classes.rootActions}>
          <modalActions.DialogActions />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalBaseComponent;
