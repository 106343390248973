import { createActions, createReducer } from 'reduxsauce';
import { defaultSop, buildSop } from 'helpers/Sop';
export const { Types, Creators } = createActions({
  setCurrentFilters: ['currentFilters', 'changeCurrent'],
  setCurrentPage: ['currentPage'],
  setArmored: ['armored'],
  setSop: ['filterKey', 'value', 'changeCurrent'],
  setFilterInputValues: ['filterValues'],
  setCoords: ['coords'],
  setClearFilters: ['currentFilters'],
});

export const INITIAL_STATE = {
  currentFilters: [],
  coords: {
    lat: null,
    long: null,
    cityId: null,
    state: null,
    zipCode: null,
  },
  currentPage: 1,
  filterInputValues: {
    location: null,
    vehicleType: 'CAR',
    searchValue: '',
    makeOptions: [],
    kmMax: null,
    armored: false,
    quotation: true,
    resale: true,
    mobija: true,
    banks: true,
    pan: true,
    yearsValues: {
      min: '',
      max: '',
    },
    priceValues: {
      min: '',
      max: '',
    },
  },
  sop: {
    spc_: 'def|rsl|rcb|egt|bnk',
    plt_: null,
    trm_: null,
    fue_: null,
    clr: null,
    lpt_: null,
    mki_: null,
    mdi_: null,
    iyr_: null,
    bds_: null,
    doo_: null,
    ste_: null,
    bkd_: null,
    blk_: null,
    wod_: null,
    avl_: null,
    ops_: null,
    ipy_: null,
    fpi_: null,
    vtp_: 'CAR',
    rsl_: null,
    egt_: null,
    rcb_: null,
    geo_: null,
  },
};

export const setCurrentFilters = (state = INITIAL_STATE, action: any) => {
  if (action.currentFilters === 'CLEAR') {
    return {
      ...INITIAL_STATE,
      coords: {
        lat: state.coords.lat,
        long: state.coords.long,
        cityId: null,
        state: null,
        zipCode: null,
      },
    };
  }

  const currentFilters = [...state.currentFilters];

  const currentFilterIndex = currentFilters.findIndex(
    (filter) =>
      filter.id === action.currentFilters?.id &&
      filter.filterTag === action.currentFilters?.filterTag,
  );

  if (action.changeCurrent) {
    if (currentFilterIndex !== -1) {
      if (action.currentFilters.name) {
        currentFilters[currentFilterIndex] = action.currentFilters;
      } else if (!action.name) {
        currentFilters.splice(currentFilterIndex, 1);
      }
    } else {
      currentFilters.push(action.currentFilters);
    }
  }

  if (!action.changeCurrent) {
    if (currentFilterIndex !== -1) {
      currentFilters.splice(currentFilterIndex, 1);
    } else {
      currentFilters.push(action.currentFilters);
    }
  }

  return {
    ...state,
    currentFilters: currentFilters,
  };
};

export const setClearFilters = (state = INITIAL_STATE, action: any) => {
  if (action.currentFilters === 'CLEAR') {
    return {
      ...state,
      currentFilters: [],
      sop: defaultSop,
      filterInputValues: {
        ...state.filterInputValues,
        location: null,
        yearsValues: {
          min: '',
          max: '',
        },
      },
    };
  }

  return state;
};

export const setFilterInputValues = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    filterInputValues: {
      ...state.filterInputValues,
      [action.filterValues.optionKey]: action.filterValues.optionValue,
    },
  };
};

export const setCurrentPage = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    currentPage: action.currentPage,
  };
};

export const setSop = (state = INITIAL_STATE, action: any) => {
  if (action.filterKey === 'CLEAR') {
    return {
      ...state,
      sop: defaultSop,
    };
  }
  const sopBuilded = buildSop(
    state.sop,
    action.filterKey,
    action.value,
    action.changeCurrent,
  );
  return {
    ...state,
    sop: sopBuilded,
  };
};

export const setCoords = (state = INITIAL_STATE, action: any) => {
  return {
    ...state,
    coords: action.coords,
    filterInputValues: {
      ...state.filterInputValues,
      location: { id: action.coords.state, name: action.coords.city },
    },
  };
};

export const HANDLERS = {
  [Types.SET_CURRENT_FILTERS]: setCurrentFilters,
  [Types.SET_CURRENT_PAGE]: setCurrentPage,
  [Types.SET_FILTER_INPUT_VALUES]: setFilterInputValues,
  [Types.SET_CLEAR_FILTERS]: setClearFilters,
  [Types.SET_SOP]: setSop,
  [Types.SET_COORDS]: setCoords,
};
export default createReducer(INITIAL_STATE, HANDLERS);
