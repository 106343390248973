import React from 'react';

function InstagramIcon({ color = '#424242' }) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.28516C2 4.52373 4.23858 2.28516 7 2.28516H17C19.7614 2.28516 22 4.52373 22 7.28516V17.2852C22 20.0466 19.7614 22.2852 17 22.2852H7C4.23858 22.2852 2 20.0466 2 17.2852V7.28516ZM7 4.28516C5.34315 4.28516 4 5.6283 4 7.28516V17.2852C4 18.942 5.34315 20.2852 7 20.2852H17C18.6569 20.2852 20 18.942 20 17.2852V7.28516C20 5.6283 18.6569 4.28516 17 4.28516H7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.2852C7 9.52373 9.23858 7.28516 12 7.28516C14.7614 7.28516 17 9.52373 17 12.2852C17 15.0466 14.7614 17.2852 12 17.2852C9.23858 17.2852 7 15.0466 7 12.2852ZM12 9.28516C10.3431 9.28516 9 10.6283 9 12.2852C9 13.942 10.3431 15.2852 12 15.2852C13.6569 15.2852 15 13.942 15 12.2852C15 10.6283 13.6569 9.28516 12 9.28516Z"
        fill={color}
      />
      <path
        d="M18 7.28516C18 7.83744 17.5523 8.28516 17 8.28516C16.4477 8.28516 16 7.83744 16 7.28516C16 6.73287 16.4477 6.28516 17 6.28516C17.5523 6.28516 18 6.73287 18 7.28516Z"
        fill={color}
      />
    </svg>
  );
}

export default InstagramIcon;
