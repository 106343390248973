/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DealerService from 'services/DealerService';
import useDealerId from 'hooks/useDealerId';
import Box from '@mui/material/Box';
import giropanCar from 'assets/giropan_car.png';
import giroPanPreAprove from 'assets/giropanpreaprove.png';

interface IStatus {
  elegivel: boolean;
  acao?: string;
}

const BANNER_INFOS = {
  PRE_APROVE: {
    title: () => (
      <Box
        component="h3"
        fontFamily="Montserrat"
        fontSize="20px"
        color="white"
        fontWeight={800}
        style={{ textTransform: 'uppercase' }}
      >
        Você é cliente{' '}
        <Box component="strong" color="#414141">
          pré-aprovado
        </Box>{' '}
      </Box>
    ),
    description:
      'Você já tem crédito pré-aprovado no Giro Pan. Utilize-o agora mesmo!',
    image: <img src={giroPanPreAprove} alt="Pessoa" />,
  },
  APROVE: {
    title: () => (
      <Box
        component="h3"
        fontFamily="Montserrat"
        fontSize="20px"
        color="white"
        fontWeight={800}
        style={{ textTransform: 'uppercase' }}
      >
        Você tem{' '}
        <Box component="strong" color="#414141">
          crédito
        </Box>{' '}
        no Giro PAN
      </Box>
    ),
    description: 'Acelere seus negócios comprando com a menor taxa do mercado!',
    image: <img src={giropanCar} alt="Corolla Cross" />,
  },
};

const ACTIONS = {
  PRE_APROVE: [
    {
      message:
        'Acessar landing page para conhecer o produto. Entrar em contato com o gerente comercial para contratar.',
      url: 'https://giropan.bancopan.com.br/',
    },
    {
      message: 'Finalizar onboarding',
      url: 'https://cadastrogiropan.bancopan.com.br/login',
    },
    {
      message:
        'Pendente assinatura contrato. Entrar em contato com o atendimento em caso de dúvida.',
      url: 'https://cadastrogiropan.bancopan.com.br/login',
    },
  ],
  APROVE: [
    {
      message: 'Sacar valor desejado.',
      url: 'https://garantiagiropan.bancopan.com.br/login',
    },
    {
      message: 'Alienar veículo(s) para liberar saldo.',
      url: 'https://garantiagiropan.bancopan.com.br/login',
    },
  ],
};

const BUTTONS_DETAILS_INFOS = {
  PRE_APROVE: {
    title: 'Aproveite o Giro Pan',
    description: 'Crédito com a menor taxa do mercado!',
    button: 'Aproveite Agora o',
  },
  APROVE: {
    title: 'Crédito disponível',
    description: 'Use seu saldo do GiroPAN neste veículo',
    button: 'Aproveite Agora o',
  },
};

const useGiroPan = (
  targetValue: number = 0,
  enabled: { enabled: boolean } = { enabled: true },
) => {
  const [status, setStatus] = React.useState<'PRE_APROVE' | 'APROVE' | null>();
  const [url, setUrl] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const dealerId = useDealerId();

  const getStatusByElegibleAndAction = ({
    elegivel,
    acao,
  }: IStatus): {
    message: 'PRE_APROVE' | 'APROVE' | null;
    url: string;
  } => {
    if (elegivel && ACTIONS.PRE_APROVE.find((item) => item.message === acao)) {
      return {
        message: 'PRE_APROVE',
        url: ACTIONS.PRE_APROVE.find((item) => item.message === acao)?.url,
      };
    }

    if (elegivel && ACTIONS.APROVE.find((item) => item.message === acao)) {
      return {
        message: 'APROVE',
        url: ACTIONS.APROVE.find((item) => item.message === acao)?.url,
      };
    }

    return null;
  };

  const giroPan = async () => {
    setLoading(true);
    const result: any = await DealerService.postGiroPan({
      dealerId,
      targetValue: targetValue || 0,
    });

    if (result?.ok) {
      if (result?.data) {
        setStatus(
          getStatusByElegibleAndAction({
            ...result?.data.results,
          })?.message,
        );
        setUrl(
          getStatusByElegibleAndAction({
            ...result?.data.results,
          })?.url,
        );
      }
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const redirectByUrl = () => {
    return window.open(url, '_blank');
  };

  React.useEffect(() => {
    if (enabled?.enabled) {
      giroPan();
    }
  }, [dealerId, enabled?.enabled]);

  return {
    status,
    redirectByUrl,
    BANNER_INFOS,
    BUTTONS_DETAILS_INFOS,
    loading,
    IsElegibleComponent: ({ children }) => (
      <>
        {status === 'APROVE' || status === 'PRE_APROVE' ? (
          <>{children}</>
        ) : null}
        {status === undefined && null}
      </>
    ),
  };
};

export default useGiroPan;
