import TextField, { TextFieldProps } from '@mui/material/TextField';
import { alpha, darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Theme } from '@mui/material';
import { DefaultTheme } from 'styles/theme/defaultTheme';
type Props = {
  backgroundColor?: string;
  height?: string;
};

type propTypes = TextFieldProps & Props;

const useStylesProposal = makeStyles<DefaultTheme, Props>(
  ({ breakpoints, transitions, palette }) =>
    createStyles({
      root: {
        border: '1px solid #e2e2e1',
        borderRadius: 4,
        [breakpoints.up('xs')]: {
          backgroundColor: (props) =>
            props?.backgroundColor ? props?.backgroundColor : '#E0F3F1',
        },
        [breakpoints.up('md')]: {
          backgroundColor: (props) =>
            props?.backgroundColor ? props?.backgroundColor : '#E9F5FE',
        },
        transition: transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
          backgroundColor: (props) =>
            props?.backgroundColor
              ? darken(props?.backgroundColor, 0.1)
              : '#d8e5f0',
        },
        '&.Mui-focused': {
          backgroundColor: (props) =>
            props?.backgroundColor
              ? darken(props?.backgroundColor, 0.1)
              : '#d8e5f0',
          boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
          borderColor: palette.primary.main,
        },
      },
      textFieldRoot: {},
      focused: {},
    }),
);

const TextFieldStyled = (props: propTypes) => {
  const classes = useStylesProposal({
    backgroundColor: props?.backgroundColor,
    height: props?.height,
  });
  return (
    <TextField
      variant="standard"
      classes={{
        root: classes.root,
      }}
      inputProps={{
        className: classes.textFieldRoot,
      }}
      {...props}
    />
  );
};

export default TextFieldStyled;
