import Button from '@mui/material/Button';
import styled from '@emotion/styled';

export const Container = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 24px;
  gap: 16px;
`;

export const CircleWrapper = styled.div`
  background: #e9eaf9;
  padding: 20px;
  border-radius: 50%;
  display: flex;
`;

export const Title = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 700;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #757575;
  text-align: center;
`;

export const BuyButton = styled(Button)`
  background: #4443bc;
  color: #fff;
  height: 40px;
`;

export const CancelButton = styled(Button)`
  color: #f44336;
  height: 40px;
`;

interface ChipProps {
  color?: string;
  bgColor?: string;
}

export const ChipComponent = styled.div<ChipProps>`
  display: flex;
  align-items: center;
  color: ${({ color }) => color ?? '#001b8b'};
  border-radius: 30px;
  padding: 4px 10px;
  background: ${({ bgColor }) => (bgColor ? bgColor : '#e9eaf9')};
  gap: 4px;
  /* xw & svg {
    width: 24px;
  } */
`;
