import { Box } from '@mui/material';
import Footer from 'components/atoms/Footer';
import { Outlet } from 'react-router-dom';
import Header from './components/HeaderBase';

const LayoutComponent = (props: any) => {
  return (
    <>
      <Header />
      <Box
        component="main"
        mt={2}
        mb={3}
        minHeight={{
          xs: '100%',
          md: 'calc(100vh - 328px)',
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </>
  );
};

export default LayoutComponent;
