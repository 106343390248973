import { useState } from 'react';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as MobicoinLogo } from 'assets/mobicoin_icon.svg';
import styled from '@emotion/styled';
import BuyMobiCoinModal from '../Modal/BuyMobiCoinModal';
import useModal from 'hooks/useModal';
import { ButtonBase, Divider } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import getEnvVarByName from 'helpers/getEnvVarByName';

const WrapperMobicoinBalance = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const TextMobicoinBalance = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`;

const ListContainer = styled.div`
  padding: 8px;
  button {
    gap: 10px;
    padding: 4px 8px;
    width: 100%;
    justify-content: flex-start;
    font-size: 12px;
    color: #212121;
    svg {
      color: #757575;
      font-size: 18px;
    }
  }
`;

const MobicoinBalance = () => {
  const { currentDealer, isFetchingBalance } = useSelector(
    (state: any) => state.userStore,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const buyMobiCoinModal = useModal();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleClickViewExtract = () => {
    window.open(
      `${getEnvVarByName('MOBIGESTOR_URL')}/${
        currentDealer.id
      }/minhas-faturas?tab=1`,
      '_blank',
    );
  };

  return (
    <>
      <WrapperMobicoinBalance onMouseEnter={handlePopoverOpen}>
        <MobicoinLogo />
        {isFetchingBalance ? (
          <CircularProgress size="18px" color="secondary" />
        ) : (
          <TextMobicoinBalance
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
          >
            {currentDealer?.mobicoinBalance?.toLocaleString() || 0}
          </TextMobicoinBalance>
        )}
        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          slotProps={{
            paper: {
              onMouseLeave: handlePopoverClose,
            },
          }}
          onClose={handlePopoverClose}
        >
          <ListContainer>
            <ButtonBase
              onClick={(event) => {
                event.stopPropagation();
                buyMobiCoinModal.handleOpenModal();
              }}
            >
              <ShoppingCartOutlinedIcon />
              Comprar
            </ButtonBase>
            <Divider />
            <ButtonBase onClick={handleClickViewExtract}>
              <ReceiptIcon /> Extrato
            </ButtonBase>
          </ListContainer>
        </Popover>
      </WrapperMobicoinBalance>
      {buyMobiCoinModal.isOpen && (
        <BuyMobiCoinModal modalActions={buyMobiCoinModal} />
      )}
    </>
  );
};

export default MobicoinBalance;
