import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationService from 'services/locationService';
import { Creators as FilterCreators } from 'store/filterStore';

const UserLocationContext = React.createContext({});

const UserLocationContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { coords } = useSelector((state: any) => state.filterStore);

  const getLocation = async () => {
    const response: any = await LocationService.getUserLocationByIp();

    if (response?.success) {
      dispatch(
        FilterCreators.setCoords({
          lat: response?.data?.latitude,
          long: response?.data?.longitude,
          city: response?.data?.city,
          cityId: response?.data?.cityId,
          state: response?.data?.state,
          zipCode: response?.data?.zipCode,
        }),
      );
      dispatch(FilterCreators.setSop('ste_', response?.data?.state || null));
      //dispatch(
      //  FilterCreators.setSop(
      //    'geo_',
      //    `${String(response?.data?.latitude)
      //      .replace('-', 'n')
      //      .replace('.', 'p')}|${String(response?.data?.longitude)
      //      .replace('-', 'n')
      //      .replace('.', 'p')}`,
      //  ),
      // );
    }
  };

  React.useEffect(() => {
    getLocation();
  }, []);

  const value = {
    coords,
  };

  return (
    <UserLocationContext.Provider value={value}>
      {children}
    </UserLocationContext.Provider>
  );
};

const withUserLocationContext = (Component) => (props) =>
  (
    <UserLocationContextProvider>
      <Component {...props} />
    </UserLocationContextProvider>
  );

const useUserLocation = () => React.useContext(UserLocationContext);

export {
  UserLocationContextProvider as default,
  UserLocationContext,
  useUserLocation,
  withUserLocationContext,
};
