import React from 'react';
import Button from './Button';
import { ReactComponent as GiroPan } from 'assets/giropan.svg';
import { ReactComponent as GiroPanDefault } from 'assets/giropandefault.svg';
import Box from '@mui/material/Box';

type Status = 'PRE_APROVE' | 'APROVE';

export type GiroPanPropTypes = {
  variant?: Status;

  height?: number | { xs?: number; md?: number; lg?: number };
  radius?: number;
  icon?: 'START' | 'END';
  children?: React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  textTransform?: 'capitalize' | 'none' | 'lowercase' | 'uppercase';
};

const GiroPanButton = ({
  variant = 'PRE_APROVE',
  height = 36,
  onClick,
  textTransform,
  radius,
  loading,
  icon = 'START',
  children,
  ...rest
}: GiroPanPropTypes) => {
  const GiroPanVariantButton = {
    PRE_APROVE: (
      <Button
        variant="primary_light"
        loading={loading}
        height={height}
        radius={radius}
        textTransform={textTransform}
        fullWidth
        {...(icon === 'START'
          ? { startIcon: <GiroPan /> }
          : { endIcon: <GiroPan /> })}
        onClick={onClick}
      >
        <Box component="h3" fontSize={{ xs: '13px', lg: '16px' }}>
          {children}
        </Box>
      </Button>
    ),
    APROVE: (
      <Button
        variant="purple"
        height={height}
        loading={loading}
        textTransform={textTransform}
        fullWidth
        radius={radius}
        {...(icon === 'START'
          ? { startIcon: <GiroPanDefault /> }
          : { endIcon: <GiroPanDefault /> })}
        onClick={onClick}
      >
        <Box component="h3" fontSize={{ xs: '13px', lg: '16px' }}>
          {children}
        </Box>
      </Button>
    ),
  };

  return GiroPanVariantButton[variant];
};

export default GiroPanButton;
