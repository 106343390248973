import React from 'react';

function TikTokIcon({ color = '#424242' }) {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8057 4.58537C16.6114 4.39024 16.4171 4.29268 16.2229 4.29268C14.3771 4.19512 12.8229 2.63415 12.6286 0.780488C12.6286 0.585366 12.5314 0.390244 12.3371 0.195122C12.1429 -1.45377e-08 11.9486 0 11.7543 0H9.22857C9.03429 0 8.74286 -1.45377e-08 8.64571 0.195122C8.45143 0.390244 8.35429 0.585366 8.35429 0.878049V13.561C8.35429 14.6341 7.48 15.4146 6.31429 15.4146C5.24571 15.4146 4.46857 14.5366 4.46857 13.4634C4.46857 12.3902 5.34286 11.5122 6.31429 11.5122H6.41143H6.50857C6.60571 11.5122 6.8 11.5122 6.89714 11.5122C6.99429 11.5122 7.09143 11.4146 7.18857 11.3171C7.28571 11.2195 7.38286 11.122 7.38286 11.0244C7.48 10.9268 7.48 10.8293 7.48 10.6341V8C7.48 7.80488 7.38286 7.51219 7.28571 7.41463C7.18857 7.21951 6.99429 7.12195 6.70286 7.12195H6.60571C6.50857 7.12195 6.41143 7.12195 6.31429 7.12195C2.81714 7.12195 0 10.0488 0 13.561C0 17.0732 2.81714 20 6.31429 20C9.81143 20 12.6286 17.0732 12.6286 13.561V8.09756C13.6 8.78049 14.6686 9.07317 15.7371 9.07317C15.9314 9.07317 16.1257 9.07317 16.2229 9.07317C16.4171 9.07317 16.6114 8.97561 16.8057 8.78049C17 8.58537 17 8.39024 17 8.19512V5.17073C17 4.97561 17 4.78049 16.8057 4.58537ZM10.1029 11.5122V1.65854H11.0743C11.56 3.70732 13.2114 5.36585 15.2514 5.7561V7.12195C14.0857 7.02439 13.1143 6.34146 12.4343 5.36585C12.3371 5.17073 12.1429 5.07317 12.0457 4.97561C11.8514 4.87805 11.6571 4.87805 11.4629 4.97561C11.2686 5.07317 11.0743 5.17073 10.9771 5.26829C10.9771 5.65854 10.88 5.85366 10.88 6.04878V13.561C10.88 16.0976 8.84 18.2439 6.31429 18.2439C3.78857 18.2439 1.74857 16.1951 1.74857 13.561C1.74857 11.2195 3.4 9.26829 5.73143 8.97561V9.95122C3.98286 10.2439 2.62286 11.7073 2.62286 13.561C2.62286 15.6098 4.27429 17.2683 6.21714 17.2683C8.25714 17.2683 10.0057 15.6098 10.0057 13.561"
        fill={color}
      />
    </svg>
  );
}

export default TikTokIcon;
