import React from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import ButtonBase from '@mui/material/ButtonBase';
import { useSelector } from 'react-redux';
import { logout } from 'auth/AuthService';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const UserInfoComponent = ({ selectModalActions, open }) => {
  const currentDealer = useSelector(
    (state: any) => state.userStore?.currentDealer,
  );
  const currentUser = useSelector((state: any) => state.userStore?.currentUser);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpen = Boolean(anchorEl);
  const id = open ? 'user-menu' : undefined;

  return (
    <>
      <Box component="div" display="flex" alignItems="center" gap="8px">
        <ButtonBase onClick={() => selectModalActions.handleOpenModal()}>
          <Box component="p" fontSize="14px" display="flex" alignItems="center">
            {currentDealer?.name} <ExpandMoreIcon fontSize="small" />
          </Box>
        </ButtonBase>
        <Box component="div" height="32px" width="1px" bgcolor="#E0E0E0" />
        <ButtonBase onClick={handleClick} aria-describedby="user-menu">
          <Avatar alt="User">{currentUser?.firstName?.slice(0, 1)}</Avatar>
        </ButtonBase>
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            component="div"
            display="flex"
            flexDirection="column"
            p={1}
            width={180}
            max-width={180}
          >
            <Box component="p">Olá, {currentUser?.firstName}</Box>
            <Box component="div" mt={1}>
              <ButtonBase onClick={() => logout()}>Sair</ButtonBase>
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default UserInfoComponent;
