import React from 'react';

import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return (
    <Box ref={ref}>
      <Slide direction="up" {...props}>
        {props.children}
      </Slide>
    </Box>
  );
});

const FullscreenModalComponent = ({
  modalActions,
  arrowBackIcon = false,
  headerTitle = 'Header Title',
  children,
}) => {
  return (
    <Dialog
      fullScreen
      open={modalActions?.isOpen}
      onClose={modalActions?.handleCloseModal}
      TransitionComponent={Transition}
    >
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={{ xs: 2, md: 3 }}
      >
        <Box
          component="div"
          display="flex"
          alignItems="center"
          gap="8px"
          onClick={arrowBackIcon ? modalActions?.handleCloseModal : undefined}
        >
          {arrowBackIcon ? <ArrowBackIosIcon fontSize="small" /> : null}
          <Box
            component="strong"
            fontSize={{ xs: '16px', md: '24px' }}
            color="other.primary"
            fontWeight={700}
          >
            {headerTitle}
          </Box>
        </Box>
        <CloseIcon fontSize="small" onClick={modalActions?.handleCloseModal} />
      </Box>
      <Box component="hr" width="100%" border="1px solid" color="#eee" />
      {children}
    </Dialog>
  );
};

export default FullscreenModalComponent;
