import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { DefaultTheme } from 'styles/theme/defaultTheme';

const useStyles = makeStyles<DefaultTheme>(
  ({ breakpoints, transitions, palette }) =>
    createStyles({
      root: {
        color: '#333',
        border: '1px solid #e2e2e1',
        borderRadius: 4,
        padding: 8,
      },
      focused: {
        color: '#333',
        border: '1px solid #e2e2e1',
        borderRadius: 4,
        padding: 8,
        [breakpoints.up('xs')]: {
          backgroundColor: '#fff',
        },
        [breakpoints.up('md')]: {
          backgroundColor: '#fff',
        },
        transition: transitions.create(['border-color', 'box-shadow']),
        '&::label': {
          top: '0',
          left: '0',
        },
        '&:hover': {
          backgroundColor: '#fff',
        },
        '&.Mui-focused': {
          backgroundColor: '#fff',
          boxShadow: `${alpha(palette.primary.main, 0.25)} 0 0 0 2px`,
          borderColor: palette.primary.main,
        },
      },
    }),
);

const useLabelStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: '#333',
      padding: 8,
    },
    focused: {
      padding: 0,
    },
  }),
);

const SearchInputComponent = ({
  placeholder = 'Buscar',
  label = 'Buscar',
  searchValue,
  handleInputChange,
  handleSearchOnClick,
  handleKeyPress,
}) => {
  const classes = useStyles();
  const labelClasses = useLabelStyles();

  return (
    <TextField
      variant="standard"
      label={label}
      value={searchValue}
      InputLabelProps={{
        classes: labelClasses,
      }}
      placeholder={placeholder}
      onChange={handleInputChange}
      onKeyPress={(e) => handleKeyPress(e, searchValue)}
      style={{ width: '100%', padding: 0, margin: 0 }}
      InputProps={{
        classes,
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => handleSearchOnClick(searchValue)}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInputComponent;
