import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as MobiCoin } from 'assets/mobicoin_icon.svg';
import TextField from 'components/atoms/Input/TextField';
import ModalBase from 'components/atoms/Modal/ModalBase';
import { Creators as UICreators } from 'store/uiStore';
import proposalService from 'services/ProposalService';
import {
  Container,
  CircleWrapper,
  Title,
  Text,
  ChipComponent,
  BuyButton,
  CancelButton,
} from './styles';

const TEXT = {
  BUY: {
    title: 'Compre Mobicoins',
    text: 'Assim você conseguirá fazer propostas em carros exclusivos do Mobijá',
  },
  REQUIRED: {
    title: 'Para efetuar a proposta você precisa ter ao menos 300 Mobicoin',
    text: 'Para dar lances adquira Mobicoins na nossa plataforma',
  },
};

const BuyMobiCoinModal = ({ modalActions, type = 'BUY' }) => {
  const dispatch = useDispatch();
  const { currentDealer } = useSelector((state: any) => state.userStore);
  const [state, setState] = useState({
    isFetching: false,
    totalAmount: 0,
    totalMobiCoin: 0,
    unitPriceMobicoin: 0,
    fetchingUnitPrice: false,
  });

  const onChange = (event) => {
    const { value } = event.target;
    setState((prevState) => ({
      ...prevState,
      totalMobiCoin: value.replace(/\D/g, ''),
    }));
  };

  const getUnitPriceMobicoin = useCallback(async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        fetchingUnitPrice: true,
      }));
      const response = await proposalService.getUnitPrice();
      if (response.success) {
        setState((prevState) => ({
          ...prevState,
          unitPriceMobicoin: response.data?.unit_price,
        }));
        return;
      }
      throw new Error();
    } catch {
      dispatch(
        UICreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.',
          true,
        ),
      );
    } finally {
      setState((prevState) => ({
        ...prevState,
        fetchingUnitPrice: false,
      }));
    }
  }, [dispatch]);

  const buyMobiCoins = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        isFetching: true,
      }));
      const response = await proposalService.createBillAndCustomer(
        currentDealer.id,
        state.totalMobiCoin,
      );
      if (response.success) {
        window.open(response.data?.paymentLink, '_blank')?.focus();
        modalActions?.handleCloseModal();
        dispatch(
          UICreators.snackbarManagement(
            'success',
            'Boleto gerado com sucesso.',
            true,
          ),
        );
        return;
      }
      throw new Error();
    } catch (e) {
      dispatch(
        UICreators.snackbarManagement(
          'error',
          'Ocorreu um erro inesperado.',
          true,
        ),
      );
    } finally {
      setState((prevState) => ({
        ...prevState,
        isFetching: false,
      }));
    }
  };

  useEffect(() => {
    getUnitPriceMobicoin();
  }, [getUnitPriceMobicoin]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      totalAmount: Number(state.totalMobiCoin) * state.unitPriceMobicoin,
    }));
  }, [state.totalMobiCoin, state.unitPriceMobicoin]);

  return (
    <ModalBase
      modalActions={modalActions}
      headerTitle=""
      headerTextColor="other.primary"
      disableHeader
    >
      <Container>
        <CircleWrapper>
          <MobiCoin />
        </CircleWrapper>
        <Title>{TEXT[type].title}</Title>
        {state.fetchingUnitPrice ? (
          <>
            <CircularProgress color="secondary" />
          </>
        ) : !state.unitPriceMobicoin ? (
          <Text>
            não foi possivel consultar o preço do mobicoin para continuar com a
            transação.
          </Text>
        ) : (
          <>
            <Text>{TEXT[type].text}</Text>
            <ChipComponent color={'#008b84'} bgColor={'#e0f2f1'}>
              {`Mobicoin:`} <MobiCoin width={'24px'} />
              {state.unitPriceMobicoin.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </ChipComponent>
            <TextField
              label="Quantidade"
              variant="filled"
              fullWidth
              value={state.totalMobiCoin}
              onChange={onChange}
            />
            <ChipComponent>
              Total:
              {state.totalAmount.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </ChipComponent>
            {state.isFetching ? (
              <CircularProgress color="secondary" />
            ) : (
              <>
                <BuyButton
                  fullWidth
                  onClick={buyMobiCoins}
                  disabled={state.isFetching}
                >
                  Comprar mobicoin
                </BuyButton>
                <CancelButton
                  fullWidth
                  onClick={modalActions?.handleCloseModal}
                  disabled={state.isFetching}
                >
                  Cancelar
                </CancelButton>
              </>
            )}
          </>
        )}
      </Container>
    </ModalBase>
  );
};

export default BuyMobiCoinModal;
